.saas-landing {
    h1 {
        font-size: 50px;
    }
    .cta-form{
        background: #fefefe;
        border-radius: 13px;
        // overflow: hidden;
        padding: 5px;
        flex-wrap: wrap;
        box-shadow: 0 0 0 1px var(--border-brutal);
        @media (max-width: 575.98px){
            padding: 0;
            background: transparent;
            box-shadow: none;
        }
        .cta-input{
            flex-grow: 1;
            @media (max-width: 575.98px){
                margin-bottom: 16px;
            }
            input{
                border: 0;
                width: 100%;
                border-radius: 10px;
                line-height: 48px;
                padding: 0 5px 0 25px;
                box-shadow: none;
                // &:focus{box-shadow: 0 0 0 1px var(--border-brutal);}
                @media (max-width: 575.98px){
                    box-shadow: 0 0 0 1px var(--border-brutal);
                }
            }
        }
        .cta-btn{
            @media (max-width: 575.98px){
                width: 100%;
                display: flex;
            }
            .btn{
                width: 100%;
            }
        }
    }
    .hero-image-banner-container{
        position: relative;
        background: linear-gradient(180deg, var(--bg-shade-1) 0%, var(--bg-shade-1) 50%, #ffffff 50%,#ffffff 100%);
        margin-bottom: 120px;
        @media (max-width: 991.98px) {
            margin-bottom: 100px;
        }
        @media (max-width: 767.98px) {
            margin-bottom: 80px;
        }
        @media (max-width: 575.98px) {
            margin-bottom: 60px;
        }
        .hero-image-banner{
            max-width: 1024px;
            z-index: 50;
        }
        &::after{
            content: '';
            display: block;
            height: 1px;
            width: 100%;
            background: var(--border-brutal);
            position: absolute;
            top: 50%;
        }
    }
    .feature-row-margin{
        margin-top: 60px;
        @media (max-width: 767.98px) {
            margin-top: 50px;
        }
        @media (max-width: 575.98px) {
            margin-top: 40px;
        }
        @media (min-width: 1200px){
            margin-top: 80px;
        }
    }
}